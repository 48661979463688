body {
  font-family: 'Lora', serif;
  background-color: #f5f5f5; /* Light gray background */
  color: #4f4f4f; /* Dark gray for text */
  margin: 0;
}

header, footer {
  background-color: #000000; /* Soft muted purple */
  color: #e0e0e0; /* Light gray text */
  padding: 20px;
  text-align: center;
  font-family: 'Playfair Display', serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header h1 {
  color: #d4c2ea; /* Light lavender purple */
  margin: 0;
}

nav a {
  color: #d4c2ea; /* Lavender for links */
  text-decoration: none;
  padding: 10px 15px;
  font-weight: bold;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

nav a:hover {
  color: #f3e8ff; /* Light purple on hover */
  border-bottom: 2px solid #b19cd9; /* Purple underline on hover */
}

section {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

footer p {
  margin: 5px 0;
}

form label {
  display: block;
  margin-bottom: 8px;
}

button {
  background-color: #6a5d7b; /* Muted purple for button */
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #5a4c6f; /* Darker purple on hover */
}

.home-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.image-container img {
  width: 100%; /* Ensures the image container sizes the image */
  max-width: 300px; /* Limits the image width */
  border-radius: 8px; /* Soft rounded edges for the image */
}

.text-container {
  flex: 1; /* Allows the text container to take remaining space */
}

.text-container h2 {
  color: #6a5d7b; /* Muted purple to match your theme */
}

.text-container p {
  color: #4f4f4f;
  line-height: 1.6;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column; /* Stack image and text vertically */
    text-align: center;
  }

  .image-container img {
    max-width: 100%; /* Make image full-width on mobile */
  }

  nav a {
    padding: 8px; /* Reduce padding for smaller screens */
    font-size: 0.9rem; /* Smaller font size */
  }

  section {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  button {
    width: 100%; /* Full-width button on mobile */
  }
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px; /* Spacing between image and text */
}

.footer-logo {
  max-width: 70px; /* Adjust as needed */
  height: auto;
  border-radius: 4px;
}
